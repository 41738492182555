import ApiService from "../services/api.service";

const prefix = "stats";
const sumAccountPeriodBalance = prefix + "/sum-account-period-balance";
const accountPeriodBalance = prefix + "/account-period-balance";
const walletPeriodBalance = prefix + "/wallet-period-balance";
const dataSourceMoney = prefix + "/data-source-money";
const exportSourceMoney = prefix + "/export-source-money";
export default {
  sumAccountPeriodBalance(params) {
    return ApiService.post(sumAccountPeriodBalance, params);
  },

  getAccountsPeriodBalace(params) {
    return ApiService.post(accountPeriodBalance, params);
  },

  getWalletPeriodBalance(params) {
    return ApiService.post(walletPeriodBalance, params);
  },

  getDataSourceMoney(params) {
    return ApiService.get(dataSourceMoney, "", params);
  },

  exportSourceMoney(params) {
    return ApiService.get(exportSourceMoney, "", params);
  },
};
