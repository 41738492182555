<template>
  <div>
    <div class="row mb-8">
      <div class="col-lg-6 mb-lg-0 mb-6">
        <label>Ngày tổng hợp:</label>
        <div class="input-daterange input-group" id="kt_datepicker">
          <input
            type="date"
            v-model="filter.date_from"
            class="form-control datatable-input"
            name="start"
            placeholder="Từ"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="la la-ellipsis-h"></i>
            </span>
          </div>
          <input
            type="date"
            v-model="filter.date_to"
            class="form-control datatable-input"
            name="end"
            placeholder="Đến"
          />
        </div>
      </div>
      <div class="col-lg-3 mb-lg-0 mb-3">
        <label>Loại ví:</label>
        <select v-model="filter.wallet_type" class="form-control" id="balance_type">
          <option value="">Tất cả</option>
          <option v-for="item in walletType" :key="item.id" :value="item.id">{{item.text}}</option>
        </select>
      </div>

      <div class="col-lg-3 mb-lg-0 mb-3">
        <label>Loại tài khoản:</label>
        <select v-model="filter.account_type" class="form-control" id="account_type">
          <option value="">Tất cả</option>
          <option v-for="item in accountType" :key="item.id" :value="item.id">{{item.text}}</option>
        </select>
      </div>
    </div>

    <div class="row mt-8">
      <div class="col-lg-12 text-right">
        <button
          class="btn btn-primary btn-primary--icon"
          @click="createLinkSearch()"
        >
          <span>
            <i class="la la-search"></i>
            <span>Tìm kiếm</span>
          </span>
        </button>
        &nbsp;
        <button class="btn btn-primary btn-primary--icon" @click="createExport()">
          <i class="la la-cloud-download"></i>
          <span>Xuất file</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "filterAccount",
  data() {
    return {
      filter: {},
      walletType: [
        { id: 1, text: "Ví thường" },
        { id: 2, text: "Ví thưởng" },
      ],
      accountType: [
        { id: 4, text: "Cá nhân" },
        { id: 8, text: "Doanh nghiệp" },
        { id: 32, text: "Khác" },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    createLinkSearch() {
      this.$emit("setCurrentPage", 1);
      this.$emit("handleFilter", this.filter);
    },
    createExport() {
      this.$emit("handleExport", this.filter);
    },
  },
};
</script>
