<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Báo cáo thống kê nguồn tiền</h6>
              </template>
              <div class="filter-transaction">
                <filter-account
                  @setCurrentPage="setCurrentPage"
                  @handleFilter="handleFilter"
                  @handleExport="handleExport"
                ></filter-account>
              </div>
              <div class="table_wrapper" v-if="showTable">
                <b-table-simple responsive="true" hover outlined>
                  <b-thead head-variant="light">
                    <b-tr>
                      <b-th rowspan="1"></b-th>
                      <b-th colspan="9">Phát sinh tăng</b-th>
                      <b-th colspan="6">Phát sinh giảm</b-th>
                      <b-th colspan="9">Nạp tiền</b-th>
                      <b-th colspan="9">Rút tiền</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th v-for="(column, index) in col" :key="index">
                        {{ column.label }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(item, index) in items" :key="index">
                      <b-td :rowspan="1">{{ item.date }}</b-td>
                      <b-td>{{ item.receive_money_wallet }}</b-td>
                      <b-td>{{ item.arise_increase_another }}</b-td>
                      <b-td>{{ item.deposit_another }}</b-td>
                      <b-td>{{ item.deposit_direct_link }}</b-td>
                      <b-td>{{ item.deposit_transfer_momo }}</b-td>
                      <b-td>{{ item.deposit_transfer_9s }}</b-td>
                      <b-td>{{ item.deposit_napas_paygate }}</b-td>
                      <b-td>{{ item.deposit_paygate }}</b-td>
                      <b-td>{{ item.arise_increase_total }}</b-td>
                      <b-td>{{ item.wallet_transfer }}</b-td>
                      <b-td>{{ item.payment }}</b-td>
                      <b-td>{{ item.with_draw_direct_link }}</b-td>
                      <b-td>{{ item.bank_transfer }}</b-td>
                      <b-td>{{ item.arise_decrease_another }}</b-td>
                      <b-td>{{ item.arise_decrease_total }}</b-td>
                      <b-td>{{ item.BIDV }}</b-td>
                      <b-td>{{ item.MBBANK }}</b-td>
                      <b-td>{{ item.STBBANK }}</b-td>
                      <b-td>{{ item.TCB }}</b-td>
                      <b-td>{{ item.VPB }}</b-td>
                      <b-td>{{ item.CTG }}</b-td>
                      <b-td>{{ item.MSB }}</b-td>
                      <b-td>{{ item.VCCB }}</b-td>
                      <b-td>{{ item.ACB }}</b-td>
                      <b-td>{{ item.WR_BIDV }}</b-td>
                      <b-td>{{ item.WR_MBBANK }}</b-td>
                      <b-td>{{ item.WR_STBBANK }}</b-td>
                      <b-td>{{ item.WR_VPB }}</b-td>
                      <b-td>{{ item.WR_CTG }}</b-td>
                      <b-td>{{ item.WR_MSB }}</b-td>
                      <b-td>{{ item.WR_VCCB }}</b-td>
                      <b-td>{{ item.WR_ACB }}</b-td>
                      <b-td>{{ item.WR_NAPAS }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
<!--              <div class="mt-3">-->
<!--                <b-pagination-nav-->
<!--                  :link-gen="createLinkPage"-->
<!--                  v-model="currentPage"-->
<!--                  :number-of-pages="paginate.totalPage"-->
<!--                  use-router-->
<!--                  first-number-->
<!--                  last-number-->
<!--                  align="right"-->
<!--                ></b-pagination-nav>-->
<!--              </div>-->
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import FilterAccount from "@/view/pages/stats/components/filterAccount";
import StatRepository from "@/core/repositories/statsRepository";

export default {
  name: "SourceMoney",
  mixins: [Common],
  components: { FilterAccount },
  data() {
    return {
      fields: [
        {
          key: "date",
          label: "Ngày",
        },
        {
          key: "source_type",
          label: "Loại nguồn",
        },
        {
          key: "source_name",
          label: "Tên nguồn",
        },
        {
          key: "total_value",
          label: "Số tiền",
        },
      ],
      colHeader: [
        {
          label: "Phát sinh tăng",
          key: "arisingIncrease",
        },
        {
          label: "Phát sinh giảm",
          key: "arisingDecrease",
        },
        {
          label: "Nạp tiền",
          key: "deposits",
        },
        {
          label: "Rút tiền",
          key: "withDraw",
        },
      ],
      col: [
        {
          key: "date",
          label: "Ngày",
        },
        {
          key: "receive_money_wallet",
          label: "Nhận tiền ví",
        },
        {
          key: "arise_increase_another",
          label: "Phát sinh tăng khác",
        },
        {
          key: "deposit_another",
          label: "Nạp tiền khác",
        },
        {
          key: "deposit_direct_link",
          label: "Nạp tiền liên kết",
        },
        {
          key: "deposit_transfer_momo",
          label: "Nạp tiền qua chuyển khoản momo",
        },
        {
          key: "deposit_transfer_9s",
          label: "Nạp tiền qua chuyển khoản 9S",
        },
        {
          key: "deposit_napas_paygate",
          label: "Nạp tiền qua Napas",
        },
        {
          key: "deposit_paygate",
          label: "Nạp tiền qua cổng",
        },
        {
          key: "arise_increase_total",
          label: "Tổng phát sinh tăng",
        },
        {
          key: "wallet_transfer",
          label: "Chuyển tiền ví",
        },
        {
          key: "payment",
          label: "Thanh toán",
        },
        {
          key: "with_draw_direct_link",
          label: "Rút tiền qua liên kết trực tiếp",
        },
        {
          key: "bank_transfer",
          label: "Chuyển tiền ngân hàng qua chuyển khoản",
        },
        {
          key: "arise_decrease_another",
          label: "Phát sinh giảm khác",
        },
        {
          key: "arise_decrease_total",
          label: "Tổng phát sinh giảm",
        },
        {
          key: "BIDV",
          label: "BIDV",
        },
        {
          key: "MBBANK",
          label: "MBBANK",
        },
        {
          key: "STBBANK",
          label: "STBBANK",
        },
        {
          key: "TCB",
          label: "TCB",
        },
        {
          key: "VPB",
          label: "VPB",
        },
        {
          key: "CTG",
          label: "CTG",
        },
        {
          key: "MSB",
          label: "MSB",
        },
        {
          key: "VCCB",
          label: "VCCB",
        },
        {
          key: "ACB",
          label: "ACB",
        },
        {
          key: "WR_BIDV",
          label: "BIDV",
        },
        {
          key: "WR_MBBANK",
          label: "MBBANK",
        },
        {
          key: "WR_STBBANK",
          label: "STBBANK",
        },
        {
          key: "WR_VPB",
          label: "VPB",
        },
        {
          key: "WR_CTG",
          label: "CTG",
        },
        {
          key: "WR_MSB",
          label: "MSB",
        },
        {
          key: "WR_VCCB",
          label: "VCCB",
        },
        {
          key: "WR_ACB",
          label: "ACB",
        },
        {
          key: "WR_NAPAS",
          label: "NAPAS",
        },
      ],
      filter: {},
      items: [],
      arisingIncrease: [],
      arisingDecrease: [],
      deposits: [],
      currentPage: 1,
      paginate: {
        limit: 3,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      showTable: false,
    };
  },
  methods: {
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        let response = await StatRepository.getDataSourceMoney(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data.data;
        this.items = body.data.data;
        this.paginate.total = body.data.total;
        this.paginate.totalPage = body.data.last_page;
        this.showTable = true;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    exportList: async function () {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        let response = await StatRepository.exportSourceMoney(params);
        this.$bus.$emit("show-loading", false);

        if (response.data.error_code !== 0) {
          alert("Có lỗi xảy ra, vui lòng thử lại");
          return false;
        }

        alert(response.data.message);
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    handleFilter(filter) {
      this.filter = filter;
      this.getList();
    },
    handleExport(filter) {
      this.filter = filter;
      this.exportList();
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thong ke nguon tien" }]);
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
};
</script>
<style lang="scss">
.table_wrapper {
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 20px;
  max-height: 800px;
  th {
    border: 1px solid #dedfe1 !important;
    text-align: center;
    padding: 20px 10px 10px 10px;
  }
  td {
    padding: 15px;
    border: 1px solid #ebedf3;
  }
  thead {
    position: sticky;
    top: -21px;
  }
}
</style>
